import { ConfigStore } from "./config.store";
import { makeAutoObservable } from "mobx";

export class ConfigStoreImpl implements ConfigStore {
  sidebarVisible: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  showSidebar(): void {
    this.sidebarVisible = true;
  }

  hideSidebar(): void {
    this.sidebarVisible = false;
  }
}

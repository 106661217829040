import { gql, useLazyQuery } from "@apollo/client";
import { ProfileFields } from "@hashchat/js-hashchat/dist/graphql";
import { ProfileDto } from "@hashchat/js-hashchat";
import { FC, useState } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import { Button } from "@mui/material";
import { LensProfileList } from "../../models";
import "./CreateChannel.css";

const animatedComponents = makeAnimated();

type SelectedProfile = { label: string; value: ProfileDto };
type SelectedProfiles = SelectedProfile[];

export const SEARCH_USERS_QUERY = gql`
  query SearchUsers($request: SearchQueryRequest!) {
    search(request: $request) {
      ... on ProfileSearchResult {
        items {
          ...ProfileFields
        }
      }
    }
  }
  ${ProfileFields}
`;

interface Props {
  onSubmit: (lensProfileList: LensProfileList) => void;
}

const SearchLensProfiles: FC<Props> = ({ onSubmit }) => {
  // Variables
  // const request = { query, type: 'PROFILE', limit: 10 };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchText, setSearchText] = useState("");
  const [selectedLensHandles, setSelectedLensHandles] =
    useState<LensProfileList>(new LensProfileList());

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchUsers, { data: searchUsersData, loading: searchUsersLoading }] =
    useLazyQuery(SEARCH_USERS_QUERY);

  const handleSearch = (inputValue: string) => {
    setSearchText(inputValue);
    searchUsers({
      variables: {
        request: {
          type: "PROFILE",
          query: inputValue,
          limit: 8,
        },
      },
    });
  };

  const loadoptions = async (inputValue: string) => {
    handleSearch(inputValue);
    return searchUsersData?.search?.items?.map((profile: ProfileDto) => ({
      label: profile.handle,
      value: profile,
    }));
  };

  const handleSubmit = (options: any) => {
    console.log("selectedLensHandles: ", selectedLensHandles);
    onSubmit(selectedLensHandles);
  };

  const handleChange = (selectedOptions: SelectedProfiles) => {
    const profileDtos = selectedOptions.map((item) => item.value);
    setSelectedLensHandles(new LensProfileList(profileDtos));
  };

  // const customStyles = {
  //   control: (_: any) => ({
  //     width: 'auto'
  //   }),
  // }

  return (
    <>
      <div className="wallet-msg-container" aria-hidden="true">
        <form onSubmit={handleSubmit}>
          <div className="input-btn-container" style={{ width: "20rem" }}>
            <AsyncSelect
              // styles={customStyles}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  minWidth: '100%',
                  width: 'max-content'
                }),
              }}
              placeholder="Lens Handle"
              closeMenuOnSelect={true}
              components={animatedComponents}
              isMulti
              cacheOptions
              defaultOptions
              onInputChange={loadoptions}
              loadOptions={loadoptions}
              loadingMessage={() => 'searching...'} 
              onChange={(options) => handleChange(options as SelectedProfiles)}
            />

            <Button
              sx={{
                padding: "7px",
              }}
              className="to-address-btn"
              size="small"
              variant="contained"
              onClick={handleSubmit}
              style={{
                backgroundColor: "#00bfff",
                boxShadow: "none",
              }}
            >
              Enter
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SearchLensProfiles;

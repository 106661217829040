import { EntityList } from "./core/entity-list";
import { LensProfile } from "./lensProfile.model";
import { ProfileDto } from "@hashchat/js-hashchat";
import { ChainID } from "../types";

export class LensProfileList extends EntityList<LensProfile> {
  constructor(dtos: ProfileDto[] = []) {
    super(dtos, LensProfile);
  }

  getStreamIds():string[] {
    return this.map(profile => profile.getUserId(ChainID.ETH.toString()))
  }
}

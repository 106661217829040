import React, { useContext } from "react";
import { Stores, stores } from "../stores";

export const StoresContext = React.createContext<Stores>(stores);

export const StoresProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>
  );
};

export const useStores = () => {
  return useContext(StoresContext);
};

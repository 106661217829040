export enum ERCStandard {
  ERC721 = "ERC721",
  ERC1155 = "ERC1155",
  POAP = "POAP",
}

export enum Namespace {
  EIP155 = "eip155",
  SOLANA = "solana",
  POLYGON = "polygon",
  XDAI = "xdai",
  ETHEREUM = "ethereum",
}

export enum Chain {
  POLYGON = "polygon",
  XDAI = "xdai",
  ETHEREUM = "ethereum",
}

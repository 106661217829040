import "./WelcomeConnectContainer.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import Logo from '../../assets/img/logo.svg';

export const WelcomeConnectContainer = () => {
  return (
    <div className="container">
      <img src={Logo} alt="logo" />
      {" "}
      <div className="welcome-title">
        Welcome to <b>Hashchat</b>
      </div>
      <div style={{ marginTop: "30px" }}>
        <ConnectButton
          label="Connect Wallet to Sign in"
          chainStatus={{ smallScreen: 'icon', largeScreen: 'full' }}
          showBalance={{ smallScreen: false, largeScreen: false }}
        />
      </div>
    </div>
  );
};

import { AccountId } from "caip";
import LitJsSdk from "lit-js-sdk";
import { Namespace } from "../types";

const CHAINS: Record<string, string> = Object.keys(LitJsSdk.LIT_CHAINS)
  .map((k) => {
    return { [LitJsSdk.LIT_CHAINS[k].chainId.toString()]: k };
  })
  .reduce((prev, cur) => {
    return { ...prev, ...cur };
  });

/**
 * Extended version of AccountId from CAIP
 */
export class UserAccount extends AccountId {
  getChain(): string {
    switch (this.chainId.namespace) {
      case Namespace.EIP155:
        return CHAINS[this.chainId.reference];
      case Namespace.SOLANA:
        return Namespace.SOLANA;
      default:
        // TODO: Need a solution for default value. Should not throw errors in models
        throw new Error("Unknown chain");
    }
  }
}

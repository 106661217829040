import App from "./App";
import * as serviceworkerRegistration from "./serviceWorkerRegistration";
import { StrictMode } from "react";
import { createRoot } from 'react-dom/client';

createRoot(
    document.getElementById("root")!,
  )
  .render(
    <StrictMode>
      <App />
    </StrictMode>,
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceworkerRegistration.register();

import React from "react";
import { observer } from "mobx-react-lite";
import "./ProfileModal.css";
import { useStores } from "../../hooks";

export const ProfileModal = observer(({ onClose }: { onClose: () => void }) => {
  const {
    authStore: { lensAccount },
  } = useStores();
  const { profile } = lensAccount ?? {};

  return (
    <div className="profile-modal">
      <div className="profile-card">
        <div
          className="profile-header"
          style={{ backgroundImage: `url(${profile?.coverImage})` }}
        >
          <div className="close-profile" onClick={onClose}>
            X
          </div>
          <div className="profile-image-wrapper">
            <img
              src={lensAccount?.avatar ?? "/placeholder/avatar.png"}
              alt="Profile"
              className="profile-image"
            />
          </div>
        </div>
        <div className="profile-info">
          <div className="profile-row">
            <div>
              <div className="profile-info-heading">Name</div>
              <div className="profile-info-content">{profile?.name}</div>
            </div>
            <div>
              <div className="profile-info-heading">Handle</div>
              <div className="profile-info-content">{profile?.handle}</div>
            </div>
          </div>
          <div className="profile-row">
            <div>
              <div className="profile-info-heading">Following</div>
              <div className="profile-info-content">
                {profile?.followingCount}
              </div>
            </div>
            <div>
              <div className="profile-info-heading">Followers</div>
              <div className="profile-info-content">
                {profile?.followerCount}
              </div>
            </div>
          </div>
          <div>
            <div className="profile-info-heading">Wallet ID</div>
            <div
              className="profile-info-content"
              title={profile?.walletId}
              onClick={
                profile?.walletId
                  ? () => navigator.clipboard.writeText(profile?.walletId)
                  : undefined
              }
            >
              {profile?.getShortWalletId()}
            </div>
          </div>
          <div>
            <div className="profile-info-heading">Bio</div>
            <div className="profile-info-content bio">{profile?.bio}</div>
          </div>
        </div>
        <div className="edit-profile">
          <a
            href={`https://www.lensfrens.xyz/${profile?.handle}`}
            target="_blank"
            rel="noreferrer"
          >
            Edit LENS Profile
          </a>
        </div>
      </div>
    </div>
  );
});

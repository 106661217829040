/**
 * Base class for DTO's that holds the state of the object without
 * any modifications
 */
import _ from "lodash";

export abstract class Entity<T extends Record<string, any>> {
  private readonly _id: string;

  /**
   * Raw data as received from the network
   * This is immutable and existence checks must be done before
   * accessing any nested properties.
   * Only used properties in the app can be exposed by data models.
   */
  protected readonly dto: T;

  /**
   * @param dto Dto as received from server
   * @param keyId Key path separated by dots if "id" is not present at root level
   * Default key is "id".
   */
  constructor(dto: T, keyId?: string) {
    this._id = keyId ? _.get(dto, keyId, "") : dto?.["id"];
    this.dto = dto;
  }

  get id(): string {
    return this._id;
  }

  /**
   * @returns T - Raw data of this model
   */
  getDto(): T {
    return this.dto;
  }

  // TODO: Remove duplicates of this in models
  getShortId(): string {
    const item = this.id.split("_").pop() ?? "";

    if (item.length <= 10) {
      return item;
    }

    return item.slice(0, 5) + "..." + item.slice(-5);
  }
}

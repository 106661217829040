import React from "react";
import { CloseThreadIcon } from "../../../../assets";
import { ThreadHeaderContainerProps } from "./thread-header.container.props";

export const ThreadHeader: React.FC<ThreadHeaderContainerProps> = (props) => {
  const { closeThread, setPinsOpen, thread } = props;

  const getReplyCount = () => {
    if (!thread?.reply_count) return "";
    if (thread.reply_count === 1) return "1 reply";
    return `${thread.reply_count} Replies`;
  };

  return (
    <div className="custom-thread-header">
      <div className="custom-thread-header__left">
        <p className="custom-thread-header__left-title">Thread</p>
        <p className="custom-thread-header__left-count">{getReplyCount()}</p>
      </div>
      <CloseThreadIcon {...{ closeThread, setPinsOpen }} />
    </div>
  );
};

import { Channel as StreamChannel } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { EntityList } from "./core/entity-list";
import { Channel } from "./channel.model";

export class ChannelList extends EntityList<
  Channel,
  StreamChannel<DefaultStreamChatGenerics>
> {
  constructor(dtos: StreamChannel<DefaultStreamChatGenerics>[] = []) {
    super(dtos, Channel);
    this.discardInvalidChannels();
  }

  /**
   * Removes corrupted channels from the list to prevent crashes
   */
  discardInvalidChannels() {
    const discardIds: string[] = [];

    this.each((channel) => {
      if (!channel.isValid()) {
        discardIds.push(channel.id);
        console.error(`Invalid channel ID: ${channel.id}`);
      }
    });

    discardIds.map((id) => this.removeItem(id));
  }

  getNFTCids(): string[] {
    const items: string[] = [];

    this.each((channel) =>
      channel.isNFT()
        ? items.push(channel.cid.split("_").pop() as string)
        : undefined
    );

    return items;
  }

  getGnosisCids(): string[] {
    const items: string[] = [];

    this.each((channel) =>
      channel.isSafe()
        ? items.push(channel.cid.split("_").pop() as string)
        : undefined
    );

    return items;
  }

  getPoapCids(): string[] {
    return this.map((channel) => channel.cid.replace("team:poap_", ""));
  }

  getDMCids(): string[] {
    return this.map((channel) => channel.cid.replace("!members-", ""));
  }

  hasPoapChannel(poapCid: string): boolean {
    return this.getPoapCids().includes(poapCid);
  }

  hasDMChannel(dmCid: string): boolean {
    return this.getDMCids().includes(dmCid);
  }

  hasNftChannel(nftCid: string): boolean {
    return this.getNFTCids().includes(nftCid);
  }

  hasGnosisChannel(gnosisCid: string): boolean {
    return this.getGnosisCids().includes(gnosisCid);
  }
}

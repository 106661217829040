import { ApiServiceImpl, ENV } from "../core/api.service.impl";
import { EnsService } from "./ens.service";
import { ServiceResponse } from "../service-response";
import { ethers } from "ethers";

export class EnsServiceImpl extends ApiServiceImpl implements EnsService {
  private readonly web3Infura = new ethers.providers.InfuraProvider(
    "homestead",
    ApiServiceImpl.getEnv(ENV.INFURA_KEY)
  );

  async lookupAddress(
    account: string
  ): Promise<ServiceResponse<string | null>> {
    try {
      const ensName = await this.web3Infura.lookupAddress(account);
      return this.success<string | null>(ensName?.toLocaleLowerCase() ?? null);
    } catch (e) {
      return this.error<string | null>(e);
    }
  }

  async resolveName(name: string): Promise<ServiceResponse<string | null>> {
    try {
      const resolvedName = await this.web3Infura.resolveName(name);
      return this.success<string | null>(
        resolvedName?.toLocaleLowerCase() ?? null
      );
    } catch (e) {
      return this.error<string | null>(e);
    }
  }
}

import { Avatar, useChatContext } from "stream-chat-react";

import "./ChannelEmptyState.css";

import HCAvatar from "../HCAvatar";

export const ChannelEmptyState = () => {
  const { channel, client } = useChatContext();

  const members = Object.values(channel?.state?.members || {}).filter(
    ({ user }) => user?.id !== client.userID
  );

  const getAvatarGroup = () => {
    if (!members.length) return <Avatar size={72} />;

    return (
      <div className="channel-empty__avatars">
        {members.map((member, i) => {
          if (i > 2) return null;
          return (
            // <Avatar
            //   key={i}
            //   image={member.user?.image}
            //   name={member.user?.name}
            //   size={72}
            // />
            <HCAvatar key={i} userId={member.user?.id || ""} size={72} />
          );
        })}
      </div>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getUserText = () => {
    if (members.length === 1) {
      return (
        <span className="channel-empty__user-name">{`@${
          members[0].user?.name ?? members[0].user?.id
        }`}</span>
      );
    }

    if (members.length === 2) {
      return (
        <span className="channel-empty__user-name">{`@${
          members[0].user?.name || members[0].user?.id
        } and @${members[1].user?.name || members[1].user?.id}`}</span>
      );
    }

    let memberString = "";

    members.forEach((member, i) => {
      if (i !== members.length - 1) {
        memberString = `${memberString}@${
          member?.user?.name || member?.user?.id
        }, `;
      } else {
        memberString = `${memberString} and @${
          member?.user?.name || member?.user?.id
        }`;
      }
    });

    return (
      <span className="channel-empty__user-name">
        {memberString || "the Universe"}
      </span>
    );
  };

  return (
    <div className="channel-empty__container">
      {/* {channel?.type === "team" ? <HashIcon /> : getAvatarGroup()} */}
      <div className="channel-empty__first">
        New Chat Group
        {channel?.type === "team" ? " in " : " with "}
        {channel?.type === "team"
          ? `#${channel?.data?.name || channel?.data?.id}`
          : getAvatarGroup()}
        .
      </div>
      <div className="channel-empty__second">
        Send messages, attachments, links, emojis, and more.
      </div>
    </div>
  );
};

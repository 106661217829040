export enum ChainID {
  MATIC = 137,
  ETH = 1,
  GNO = 100,
}

export enum DebankChainID {
  matic = 137,
  eth = 1,
  xdai = 100,
}
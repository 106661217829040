import _ from "lodash";
import { FollowingDto } from "@hashchat/js-hashchat";
import { LensProfileList } from "./lensProfileList.model";
import { Entity } from "./core/entity";

export class Following extends Entity<FollowingDto> {
  followingUsers: LensProfileList;

  constructor(followingDto: FollowingDto) {
    super(followingDto);
    const profileDtos = _.map(this.following.items, (item) => item.profile);
    this.followingUsers = new LensProfileList(profileDtos);
  }

  private get following() {
    return this.dto?.following;
  }
}

import React, { useState } from "react";
import LensLogo from "../../assets/img/logo.svg";
import { LensProfile } from "../../models";
import "./LensUserCard.css";
import { observer } from "mobx-react-lite";
import { ChainID } from "../../types";

export const LensUserCard = observer(
  ({
    lensProfile,
    setSelectedUsers,
  }: {
    setSelectedUsers: React.Dispatch<
      React.SetStateAction<string[] | undefined>
    >;
    lensProfile: LensProfile;
  }) => {
    const [selected, setSelected] = useState(false);
    const handleClick = () => {
      // const senderAccountId = authStore.authSession!.accountId;
      const chainId = ChainID.ETH.toString(); // ensure it's mainnet
      const userId = lensProfile.getUserId(chainId);

      if (selected) {
        setSelectedUsers((prevUsers) =>
          prevUsers?.filter((prevUser) => prevUser !== userId)
        );
      } else {
        setSelectedUsers((prevUsers) =>
          prevUsers ? [...prevUsers, userId] : [userId]
        );
      }
      setSelected(!selected);
    };

    return (
      <div
        onClick={handleClick}
        className="user-card"
        style={{
          backgroundColor: selected ? "#03bfff" : "#e4f8ff",
        }}
      >
        <div>
          <div className="user-card-img-wrapper">
            <img
              src={lensProfile.avatar ?? LensLogo}
              className="avatar"
              alt="avatar"
            />
          </div>
        </div>
        <div
          className="card-username ellipsis"
          style={selected ? { color: "#fff" } : undefined}
        >
          {lensProfile.handle}
        </div>
      </div>
    );
  }
);

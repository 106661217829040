import { chain } from "wagmi";

export const CONFIG = {
  lensBaseUrl: "https://api.lens.dev",
  alchemyKey: process.env.REACT_APP_ALCHEMY_KEY,
};

export const POLYGON_MAINNET = {
  ...chain.polygon,
  name: "Polygon Mainnet",
  rpcUrls: { default: "https://polygon-rpc.com" },
};
export const POLYGON_MUMBAI = {
  ...chain.polygonMumbai,
  name: "Polygon Mumbai",
  rpcUrls: { default: "https://rpc-mumbai.maticvigil.com" },
};

class AppConfig {
  isMainnet(): boolean {
    return true;
  }

  getChainId(): number {
    console.log("CONFIG:  ", CONFIG);
    console.log("POLYGON_MAINNET.id: ", POLYGON_MAINNET.id);
    console.log("POLYGON_MUMBAI.id: ", POLYGON_MUMBAI.id);
    return this.isMainnet() ? POLYGON_MAINNET.id : POLYGON_MUMBAI.id;
  }

  getRpcUrl(): string {
    return this.isMainnet()
      ? `https://polygon-mainnet.g.alchemy.com/v2/${CONFIG.alchemyKey}`
      : `https://polygon-mumbai.g.alchemy.com/v2/${CONFIG.alchemyKey}`;
  }
}

export const appConfig = new AppConfig();

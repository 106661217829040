import { accountIdToUserId, Entity, ProfileDto } from "@hashchat/js-hashchat";
import { AccountId } from "caip";
import { ethers } from "ethers";

export class LensProfile extends Entity<ProfileDto> {
  get id(): string {
    return this.dto?.id ?? "";
  }

  get handle(): string {
    return this.dto?.handle ?? "";
  }

  get name(): string {
    return this.dto?.name ?? "";
  }

  get bio(): string {
    return this.dto?.bio ?? "";
  }

  get walletId(): string {
    return this.dto?.ownedBy ?? "";
  }

  private get avatarIPFS(): string {
    return this.dto?.picture?.original?.url ?? "";
  }

  private get coverImageIPFS(): string {
    return this.dto?.coverPicture?.original?.url ?? "";
  }

  get avatar(): string {
    return this.getURLfromIPFS(this.avatarIPFS);
  }

  get coverImage(): string {
    return this.getURLfromIPFS(this.coverImageIPFS);
  }

  isDefault(): boolean {
    return !!this.dto?.isDefault;
  }

  getShortWalletId(): string {
    const item = this.walletId.split("_").pop() ?? "";

    if (item.length <= 10) {
      return item;
    }

    return item.slice(0, 5) + "..." + item.slice(-5);
  }

  get followingCount(): number {
    return this.dto?.stats?.totalFollowing ?? 0;
  }

  get followerCount(): number {
    return this.dto?.stats?.totalFollowers ?? 0;
  }

  private getURLfromIPFS(url: string): string {
    const IPFS_GATEWAY = "https://lens.infura-ipfs.io/ipfs/";
    return url.replace("ipfs://", IPFS_GATEWAY);
  }

  // Getstream ID
  getUserId(chainId: string): string {
    if (!this.walletId) {
      return "";
    }

    const checksumAddress = ethers.utils.getAddress(this.walletId);

    const accountId = new AccountId({
      chainId: {
        namespace: "eip155",
        reference: chainId,
      },
      address: checksumAddress,
    });

    return accountIdToUserId(accountId);
  }
}

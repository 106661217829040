import { Entity } from "./core/entity";
import { UserResponse } from "stream-chat";

enum Role {
  ADMIN = "admin",
  USER = "user",
}

export class User extends Entity<UserResponse> {
  get name(): string {
    return this.dto?.name ?? "";
  }

  get image(): string | undefined {
    return this.dto?.image as string | undefined;
  }

  get lastActive(): string {
    return this.dto?.last_active ?? "";
  }

  get role(): Role {
    return this.dto?.role as Role;
  }

  isAdmin(): boolean {
    return this.role === Role.ADMIN;
  }
}

import React, { useState } from "react";
import {
  MessageUIComponentProps,
  useChannelStateContext,
  useMessageContext,
} from "stream-chat-react";

import "./TeamMessage.css";
import { TeamMessage } from "./TeamMessage";

import { useServices } from "../../hooks";
import {
  HashchatStreamMessage,
  StreamMessage,
  MessageType,
} from "@hashchat/js-hashchat";
import { StreamMessage as ReactStreamMessage } from "stream-chat-react";
import HCAvatar from "../HCAvatar";

type Props = MessageUIComponentProps & {
  setPinsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EncryptedTeamMessage: React.FC<Props> = (props) => {
  const { setPinsOpen } = props;
  const { channel } = useChannelStateContext();

  const { message: _message } = useMessageContext();

  const [modal, setModal] = useState(false);

  const { chatService } = useServices();

  const [decryptedMessage, setDecryptedMessage] =
    React.useState<StreamMessage | null>(null);

  const [didFail, setDidFail] = React.useState<boolean>(false);

  React.useEffect(() => {
    const decrypt = async () => {
      const _decryptedMessage = await chatService.openMessage(
        _message as HashchatStreamMessage,
        channel.cid!
      );

      if (
        _decryptedMessage.data &&
        _decryptedMessage.data.type === MessageType.GET_STREAM
      ) {
        setDecryptedMessage(_decryptedMessage.data as StreamMessage);
        setDidFail(false);
      } else {
        setDecryptedMessage(null);
        setDidFail(true);
      }
    };

    decrypt();
  }, [_message, channel, chatService]);


  const toggleModal = async () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  if (!_message.hashchatMessage || decryptedMessage?.body.isDeleted()) {
    return <></>;
  }

  if (didFail) {
    return <div>Failed to read message.</div>;
  }

  return decryptedMessage == null ? (
    <div>Decrypting...</div>
  ) : (
    <>
      <TeamMessage
        onUserClick={toggleModal}
        setPinsOpen={setPinsOpen}
        message={{
          ...(decryptedMessage.body.getDto() as ReactStreamMessage),
          user: _message.user,
        }}
      />
      {modal && (
        <div className="modal">
          <div className="overlay">
            <div className="modal-content">
              <div className="modal-top">
                <div className="modal-profile-container">
                  <div className="modal-profile-avatar">
                    <HCAvatar userId={decryptedMessage.signers[0].address} size={18}/>
                  </div>
                  <div className="modal-profile-name">
                    <h4 className="wallet-address">
                      {" "}
                      Signed by Wallet: {decryptedMessage.signers[0].address}
                    </h4>
                  </div>
                </div>

              </div>

              <button className="close-profile-modal" onClick={handleClose}>
                close
              </button>
              <div className="dm-container">
                chainId: {decryptedMessage.from.split(":")[1]}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useLensAuth } from "@hashchat/js-hashchat";
import { useStores } from "../hooks";
import { Following, LensAccount } from "../models";
import { runInAction } from "mobx";

export const LensAuth = observer(() => {
  const { authStore } = useStores();
  const data = useLensAuth({
    walletAddress: authStore.authSession?.accountId?.address ?? "",
  });

  useEffect(() => {
    if (data.profiles.length) {
      authStore.lensAccount = new LensAccount({
        profileId: data.profileId,
        profiles: data.profiles,
      });
    }

    if (data.profiles) {
      runInAction(() => {
        authStore.loadingLensAccount = false;
      });
    }

    if (data.following) {
      authStore.following = new Following(data.following);
    }

    console.log("Lens SDK: ", data);
  }, [authStore, data]);

  return <></>;
});

import { AuthStore } from "./auth.store";
import { makeAutoObservable } from "mobx";
import { AuthSession } from "@hashchat/js-hashchat";
import { Following, LensAccount } from "../../models";

export class AuthStoreImpl implements AuthStore {
  authSession: AuthSession | null = null;
  lensAccount: LensAccount | null = null;
  following: Following | null = null;
  userName: string = "";
  ensName: string = "";
  // WIP: To avoid error screen from showing before lens response
  loadingLensAccount: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }
}

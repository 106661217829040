import { Entity } from "./core/entity";
import { LensAccountDto } from "./dtos/lensAccount.dto";
// import _ from "lodash";
import { LensProfile } from "./lensProfile.model";
import { LensProfileList } from "./lensProfileList.model";

export class LensAccount extends Entity<LensAccountDto> {
  profileList: LensProfileList;

  constructor(dto: LensAccountDto) {
    super(dto);
    this.profileList = new LensProfileList(dto.profiles);
  }

  get profileId(): string {
    return this.profile.id ?? "";
  }

  get profile(): LensProfile {
    return (
      this.profileList.find((profile) => profile.isDefault()) ??
      // Because user can have only one profile that's non-default
      (this.profileList.first() as LensProfile)
    );
  }

  get avatar(): string {
    return this.profile.avatar;
  }
}

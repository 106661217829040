import React from "react";
import {
  MessageUIComponentProps,
  MessageTeam,
  useMessageContext,
  StreamMessage,
} from "stream-chat-react";

import "./TeamMessage.css";

type Props = MessageUIComponentProps & {
  setPinsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  message: StreamMessage;
};

export const TeamMessage: React.FC<Props> = (props) => {
  const { setPinsOpen, message } = props;

  const { handleOpenThread } = useMessageContext();

  const handleOpenThreadOverride = (event: React.BaseSyntheticEvent) => {
    if (setPinsOpen) setPinsOpen(false);
    handleOpenThread(event);
  };

  return (
    <div className={message.pinned ? "pinned-message" : "unpinned-message"}>
      <MessageTeam
        {...props}
        message={message}
        handleOpenThread={handleOpenThreadOverride}
      />
      {/** potentially add replies component here */}
    </div>
  );
};

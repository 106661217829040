import "./Navbar.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useStores } from "../../hooks";
import { observer } from "mobx-react-lite";
import Logo from "../../assets/img/logo.svg";
import React, { useState } from "react";
import Shield from "../../assets/img/shield.svg";
import { ProfileModal } from "../ProfileModal/ProfileModal";
import { runInAction } from "mobx";

export const Navbar = observer(() => {
  const {
    authStore: { lensAccount },
    configStore,
  } = useStores();
  const [showProfile, setShowProfile] = useState(false);

  return (
    <div className="navbar-container">
      <i
        className="fa fa-bars"
        onClick={() => (
          runInAction(() => (configStore.sidebarVisible = true)))
          }
      ></i>
      <div className="left">
        <img className="logo" src={Logo} alt="" />
        <div className="header">
          <span className="app-name">
            Hashchat <span className="app-version">v3</span>
          </span>
          <div className="e2e-tag">
            <img className="shield" src={Shield} alt="shield" />
            <p>End-to-end encrypted</p>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="nav-connect-button">
          <ConnectButton
            chainStatus={{ smallScreen: "icon", largeScreen: "full" }}
            showBalance={{ smallScreen: false, largeScreen: false }}
            accountStatus={{ smallScreen: "avatar", largeScreen: "avatar" }}
          />
        </div>
        <div className="nav-profile-image-wrapper">
          <img
            onClick={() => setShowProfile(true)}
            src={lensAccount?.avatar ?? "/placeholder/avatar.png"}
            alt="Profile"
            className="nav-profile-image"
          />
        </div>
      </div>
      {showProfile && <ProfileModal onClose={() => setShowProfile(false)} />}
    </div>
  );
});

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import { Avatar, useChatContext } from "stream-chat-react";

import "./UserList.css";

import { InviteIcon } from "../../assets";

import type { ChannelFilters, UserResponse } from "stream-chat";
import { User } from "../../models";
import { LensUserCard } from "../LensUserCard/LensUserCard";
import { useStores } from "../../hooks";
import HCAvatar from "../HCAvatar";

type ListProps = {
  children?: React.ReactNode | React.ReactNode[];
};

const ListContainer: React.FC<ListProps> = ({ children }) => {
  // const { children } = props;

  return (
    <div className="user-list__container">
      <p className="existing-contacts">Contact list:</p>
      <div className="user-list__header">
        <p>User Name</p>
        <p>Invite</p>
      </div>
      {children}
    </div>
  );
};

type ItemProps = {
  index: number;
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[] | undefined>>;
  user: UserResponse;
};

const UserItem: React.FC<ItemProps> = (props) => {
  const { setSelectedUsers, user: _user } = props;

  const [selected, setSelected] = useState(false);

  const user = useMemo(() => new User(_user), [_user]);

  if (user.isAdmin()) {
    return <></>;
  }

  const handleClick = () => {
    if (selected) {
      setSelectedUsers((prevUsers) =>
        prevUsers?.filter((prevUser) => prevUser !== user.id)
      );
    } else {
      setSelectedUsers((prevUsers) =>
        prevUsers ? [...prevUsers, user.id] : [user.id]
      );
    }
    setSelected(!selected);
  };

  return (
    <div className="user-item__wrapper" onClick={handleClick}>
      <div className="user-item__name-wrapper">
        {/* <Avatar
          image={user.image}
          name={user.name || user.getShortId()}
          size={32}
        />
        <p className="user-item__name">{user.name || user.getShortId()} </p> */}
        <HCAvatar userId={user.id} size={32}/>
      </div>

      <div className="invitation-checkbox">
        {selected ? (
          <InviteIcon />
        ) : (
          <div className="user-item__invite-empty" />
        )}
      </div>
    </div>
  );
};

type Props = {
  filters: ChannelFilters[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[] | undefined>>;
};

export const UserList: React.FC<Props> = (props) => {
  const { filters, setSelectedUsers } = props;

  // const { client } = useChatContext();
  const {
    authStore: { following },
  } = useStores();

  const [error, setError] = useState(false);
  const [listEmpty, setListEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<UserResponse[] | undefined>();

  // useEffect(() => {
  //   const getUsers = async () => {
  //     if (loading) return;
  //     setLoading(true);
  //
  //     try {
  //       const response = await client.queryUsers(
  //         { id: { $ne: client.userID || "" }, ...filters },
  //         { id: 1 },
  //         { limit: 8 }
  //       );
  //
  //       if (response.users.length) {
  //         setUsers(response.users);
  //       } else {
  //         setListEmpty(true);
  //       }
  //     } catch (event) {
  //       setError(true);
  //     }
  //
  //     setLoading(false);
  //   };
  //
  //   if (client) getUsers();
  // }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    return (
      <ListContainer>
        <div className="user-list__message">
          Error loading, please refresh and try again.
        </div>
      </ListContainer>
    );
  }

  if (listEmpty) {
    return (
      <ListContainer>
        <div className="user-list__message">No users found.</div>
      </ListContainer>
    );
  }

  return (
    <div className="new-chat-wrapper">
      {loading ? (
        <div className="user-list__message">Loading users...</div>
      ) : (
        following?.followingUsers.map((lensProfile, i) => (
          <LensUserCard
            key={lensProfile.id}
            lensProfile={lensProfile}
            setSelectedUsers={setSelectedUsers}
          />
        ))
      )}
    </div>
  );
};

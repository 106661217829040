import React from "react";
import "./IconFooter.css";
import Logo from "../assets/img/logo.svg";
import Twitter from "../assets/img/twitter.svg";
import Github from "../assets/img/github.svg";
import Discord from "../assets/img/discord.svg";

function IconFooter() {
  return (
    <div className="footer-container">
      <a
        href="https://www.hashchat.xyz/"
        target="_blank"
        className="icon-container"
        rel="noreferrer"
      >
        <img height={20} alt="logo" src={Logo} />
      </a>
      <a
        href="https://twitter.com/hashchatxyz"
        target="_blank"
        className="icon-container"
        rel="noreferrer"
      >
        <img height={20} alt="logo" src={Twitter} />
      </a>
      <a
        href="https://discord.gg/CE788vNg5B"
        target="_blank"
        className="icon-container"
        rel="noreferrer"
      >
        <img height={20} alt="logo" src={Discord} />
      </a>
      <a
        href="https://github.com/hashchat-xyz"
        target="_blank"
        className="icon-container"
        rel="noreferrer"
      >
        <img height={20} alt="logo" src={Github} />
      </a>
    </div>
  );
}

export default IconFooter;
